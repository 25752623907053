import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

// Code by stewdebaker (https://stackoverflow.com/users/7307355/stewdebaker)
// From https://stackoverflow.com/a/41187919/2557554
// Licensed under CC-BY-SA 3.0
export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate() ?
            true :
        // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
            confirm('Are you sure you want to navigate away from this page? You will lose your unsaved changes.');
    }
}
