import {Injectable} from '@angular/core';
import {TableService} from '@framewerx/kendo-angular-grid';
import {
    PersonnelTimeRecord,
    PersonnelTimeRecordService,
    CrewDayPersonnelTrade,
    CrewDayPersonnelTradeService,
} from '../../../../sitewerx-api';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

const flatten = (into, node) => {
    if (node == null) return into;
    if (Array.isArray(node)) return node.reduce(flatten, into);
    into.push(node);
    return flatten(into, node.columns);
};

@Injectable({
    providedIn: 'root',
})
export class CrewDayPersonnelTradeGridService extends TableService<CrewDayPersonnelTrade> {

    constructor(http: HttpClient, private clientService: CrewDayPersonnelTradeService) {
        super(http, 'CrewDayPersonnelTrade');
    }

    getIdUrlPart(data: any | CrewDayPersonnelTrade): string {
        if (data == null) {
            return '';
        }
        return `/${ data.id }`;
    }

    protected cleanupItem(dataItem: any): any {
        delete dataItem.project;
        return super.cleanupItem(dataItem);
    }

    add(data: any | CrewDayPersonnelTrade): Observable<any> {
        return this.clientService.crewDayPersonnelTradePost(this.cleanupItem(data));
    }

    delete(data: any | CrewDayPersonnelTrade): Observable<any> {
        return this.clientService.crewDayPersonnelTradeDelete(data.id);
    }

    update(data: any | CrewDayPersonnelTrade): Observable<any> {
        return this.clientService.crewDayPersonnelTradePatch(this.cleanupItem(data), data.id);
    }

    protected mapToObject(responseData: any | CrewDayPersonnelTrade): CrewDayPersonnelTrade {
        return responseData;
    }

    protected addMetaDataToObject(responseData: any | CrewDayPersonnelTrade): any {

        if (responseData.hasOwnProperty('personnelTimeRecords') && responseData.personnelTimeRecords.length > 0) {
            const timeRecords = {};
            responseData.personnelTimeRecords.forEach((k: PersonnelTimeRecord) => {
                const obj = Object.assign({}, k);
                timeRecords[`${ k.crewDayTaskId }`] = obj;
            });
            responseData['$metadata'].timeRecords = timeRecords;
            delete responseData.personnelTimeRecords;
        }
        if (responseData.hasOwnProperty('crewDayPersonnel')
      && responseData.crewDayPersonnel.hasOwnProperty('personnel')) {
            responseData['$metadata'].personnelId = responseData.crewDayPersonnel.personnelId;
        }
        if (responseData.hasOwnProperty('crewDayPersonnel')
      && responseData.crewDayPersonnel.hasOwnProperty('personnel')
      && responseData.crewDayPersonnel.personnel.hasOwnProperty('crewDayPersonnel')
      && responseData.crewDayPersonnel.personnel.crewDayPersonnel.length > 0) {
            let items = flatten([], (responseData as CrewDayPersonnelTrade).crewDayPersonnel
                .personnel.crewDayPersonnel
                .filter(k => k.crewDayId === responseData.crewDayPersonnel.crewDayId)
                .map(f => flatten([], f.daysTrades.map(k => k.personnelTimeRecords))));
            const time = items.reduce((a: PersonnelTimeRecord, b: PersonnelTimeRecord) => {
                a.regularHours += b.regularHours;
                a.overTimeHours += b.overTimeHours;
                a.doubleTimeHours += b.doubleTimeHours;
                a.travelTimeHours += b.travelTimeHours;
                return a;
            }, {regularHours: 0, overTimeHours: 0, doubleTimeHours: 0, travelTimeHours: 0});
            responseData['$metadata'].crewTotalTime = time;
            responseData['$metadata'].crewTotalTime.totalHours =
        (time.regularHours + time.overTimeHours + time.doubleTimeHours + time.travelTimeHours);
            // responseData['$metadata'].dayTotalTime =
            items = flatten([], (responseData as CrewDayPersonnelTrade).crewDayPersonnel
                .personnel.crewDayPersonnel.map(f => flatten([], f.daysTrades.map(k => k.personnelTimeRecords))));
            const daytime = items.reduce((a: PersonnelTimeRecord, b: PersonnelTimeRecord) => {
                a.regularHours += b.regularHours;
                a.overTimeHours += b.overTimeHours;
                a.doubleTimeHours += b.doubleTimeHours;
                a.travelTimeHours += b.travelTimeHours;
                return a;
            }, {regularHours: 0, overTimeHours: 0, doubleTimeHours: 0, travelTimeHours: 0});
            responseData['$metadata'].dayTotalTime = daytime;
            responseData['$metadata'].dayTotalTime.totalHours =
        (daytime.regularHours + daytime.overTimeHours + daytime.doubleTimeHours + daytime.travelTimeHours);
        }
        return super.addMetaDataToObject(responseData);
    }
}
