import {Injectable} from '@angular/core';
import {TableService} from '@framewerx/kendo-angular-grid';
import {Personnel, PersonnelService} from '../../../../sitewerx-api';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PersonnelSearchGridService extends TableService<Personnel> {

    constructor(http: HttpClient, private clientService: PersonnelService) {
        super(http, 'Personnel');
    }

    getIdUrlPart(data: any | Personnel): string {
        if (data == null) {
            return '';
        }
        return `/${ data.id }`;
    }

    protected cleanupItem(dataItem: any): any {
        delete dataItem.project;
        return super.cleanupItem(dataItem);
    }

    add(data: any | Personnel): Observable<any> {
        return this.clientService.personnelPost(this.cleanupItem(data));
    }

    delete(data: any | Personnel): Observable<any> {
        return this.clientService.personnelDelete(data.id);
    }

    update(data: any | Personnel): Observable<any> {
        return this.clientService.personnelPatch(this.cleanupItem(data), data.id);
    }

    protected mapToObject(responseData: any | Personnel): Personnel {
        return responseData;
    }

    protected addMetaDataToObject(responseData: any | Personnel): any {
        return super.addMetaDataToObject(responseData);
    }
}
