import {Injectable} from '@angular/core';
import {TableService} from '@framewerx/kendo-angular-grid';
import {CrewDayTask, CrewDayTaskService} from '../../../../sitewerx-api';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CrewDayTaskGridService extends TableService<CrewDayTask> {

    constructor(http: HttpClient, private clientService: CrewDayTaskService) {
        super(http, 'CrewDayTask');
    }

    getIdUrlPart(data: any | CrewDayTask): string {
        if (data == null) {
            return '';
        }
        return `/${ data.id }`;
    }

    protected cleanupItem(dataItem: any): any {
        delete dataItem.project;
        return super.cleanupItem(dataItem);
    }

    add(data: any | CrewDayTask): Observable<any> {
        return this.clientService.crewDayTaskPost(this.cleanupItem(data));
    }

    delete(data: any | CrewDayTask): Observable<any> {
        return this.clientService.crewDayTaskDelete(data.id);
    }

    update(data: any | CrewDayTask): Observable<any> {
        return this.clientService.crewDayTaskPatch(this.cleanupItem(data), data.id);
    }

    protected mapToObject(responseData: any | CrewDayTask): CrewDayTask {
        return responseData;
    }

    protected addMetaDataToObject(responseData: any | CrewDayTask): any {
        return super.addMetaDataToObject(responseData);
    }
}
