import {Injectable} from '@angular/core';
import {TableService} from '@framewerx/kendo-angular-grid';
import {
    CrewLead,
    PersonnelTimeRecord,
    PersonnelTimeRecordService,
    ProjectTradeUnion,
    ProjectTradeUnionService,
} from '../../../../sitewerx-api';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PersonnelTimeGridService extends TableService<PersonnelTimeRecord> {

    constructor(http: HttpClient, private clientService: PersonnelTimeRecordService) {
        super(http, 'PersonnelTimeRecord');
    }

    getIdUrlPart(data: any | PersonnelTimeRecord): string {
        if (data == null) {
            return '';
        }
        return `/${ data.id }`;
    }

    protected cleanupItem(dataItem: any): any {
        delete dataItem.project;
        return super.cleanupItem(dataItem);
    }

    add(data: any | PersonnelTimeRecord): Observable<any> {
        return this.clientService.personnelTimeRecordPost(this.cleanupItem(data));
    }

    delete(data: any | PersonnelTimeRecord): Observable<any> {
        return this.clientService.personnelTimeRecordDelete(data.id);
    }

    update(data: any | PersonnelTimeRecord): Observable<any> {
        return this.clientService.personnelTimeRecordPatch(this.cleanupItem(data), data.id);
    }

    protected mapToObject(responseData: any | PersonnelTimeRecord): PersonnelTimeRecord {
        return responseData;
    }

    protected addMetaDataToObject(responseData: any | PersonnelTimeRecord): any {
        return super.addMetaDataToObject(responseData);
    }
}
